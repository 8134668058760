var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-up"}},[(_vm.isActive)?_c('div',{staticClass:"content-container content-half"},[_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-column padding-div w-100"},[_c('div',{staticClass:"fs-32px fw-700 color-dark mb-5"},[_vm._v(" Informe os dados do(a) representante legal ")]),_c('div',{staticClass:"content-contents"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"mb-4 mt-5"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v(" Qual o nome do(a) representante? ")]),_c('div',{staticClass:"single-input"},[_c('v-text-field',{staticClass:"input-caps",attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.onlyalpha],"placeholder":"Digite sua resposta aqui"},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})],1)]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"w-100 padding-right"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v(" O e-mail do(a) representante legal: ")]),_c('div',{staticClass:"single-input"},[_c('v-text-field',{attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.email],"placeholder":"Digite sua resposta aqui"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]),_c('div',{staticClass:"w-100 padding-left"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v("O telefone do(a) representante legal:")]),_c('div',{staticClass:"single-input"},[_c('v-text-field-simplemask',{staticClass:"input-caps",attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.celular],"properties":{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    placeholder: '',
  },"options":{
    inputMask: '(##) #####-####',
    outputMask: '###########',
    empty: null,
    applyAfter: false,
    alphanumeric: false,
    lowerCase: false,
  },"focus":_vm.focus},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send($event)},"focus":function($event){_vm.focus = false}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send($event)}},model:{value:(_vm.telefone),callback:function ($$v) {_vm.telefone=$$v},expression:"telefone"}})],1)])])]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v("Data de nascimento do(a) representante legal:")]),_c('div',{staticClass:"single-input"},[_c('v-text-field-simplemask',{attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.validdate],"properties":{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    placeholder: 'DD/MM/YYYY',
  },"options":{
    inputMask: '##/##/####',
    outputMask: '##/##/####',
    empty: null,
    applyAfter: false,
    alphanumeric: false,
    lowerCase: false,
  }},model:{value:(_vm.dataNascimento),callback:function ($$v) {_vm.dataNascimento=$$v},expression:"dataNascimento"}})],1)]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v(" Qual o parentesco do(a) representante? ")]),_c('div',{staticClass:"n-form-select"},[_c('v-select',{staticClass:"input-caps",attrs:{"required":"","rules":[_vm.rules.required],"placeholder":"Selecione o tipo de parentesco","items":_vm.tiposRepresentacao,"item-value":"TPRTPREPRESENTANCAO","item-text":"TPRDESTPREPRESENTACAO"},model:{value:(_vm.tipoRepresentacao),callback:function ($$v) {_vm.tipoRepresentacao=$$v},expression:"tipoRepresentacao"}})],1)]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v("Digite o número do CPF do(a) representante legal:")]),_c('div',{staticClass:"single-input"},[_c('v-text-field-simplemask',{attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.validcpf],"properties":{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    placeholder: '',
  },"options":{
    inputMask: '###.###.###-##',
    outputMask: '###.###.###-##',
    empty: null,
    applyAfter: false,
    alphanumeric: false,
    lowerCase: false,
  }},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})],1)])])],1),_c('div',{staticClass:"content-buttons"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"button-primary-bg-primary action-button",on:{"click":_vm.next}},[_vm._v(" Avançar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("east")])],1)])])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }